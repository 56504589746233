import { Routes } from '@angular/router';

import { DashboardGuard } from '@guards/dashboard.guard';
import { LoginGuard } from '@guards/login.guard';
import { ServerStatusGuard } from '@guards/server-status.guard';

import { UserAndClientResolver } from '@resolvers/user-and-client.resolver';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        redirectTo: 'dashboard/home',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        loadComponent: () => import('./layouts/admin-layout/admin-layout.component').then(c => c.AdminLayoutComponent),
        canActivate: [DashboardGuard],
        resolve: { data: UserAndClientResolver },
        children: [
            {
                path: 'home',
                loadComponent: () => import('./pages/dashboard/dashboard.component').then(c => c.DashboardComponent),
            },
            {
                path: 'audits',
                loadChildren: () => import('./pages/audits/audits.routes').then(c => c.AuditsRoutes),
            },
            {
                path: 'assets',
                loadChildren: () => import('./pages/assets/assets.routes').then(c => c.AssetsRoutes),
            },
            {
                path: 'projects',
                loadChildren: () => import('./pages/projects/projects.routes').then(c => c.ProjectsRoutes),
            },
            {
                path: 'users',
                loadChildren: () => import('./pages/users/users.routes').then(c => c.UsersRoutes),
            },
        ]
    },
    {
        path: ':type/:id/:sectionIndex/:questionIndex',
        loadComponent: () => import('./pages/images/images.component').then(c => c.ImagesComponent),
        canActivate: [DashboardGuard],
    },
    {
        path: ':type/:id/:sectionIndex/:repeatSectionIndex/:questionIndex',
        loadComponent: () => import('./pages/images/images.component').then(c => c.ImagesComponent),
        canActivate: [DashboardGuard],
    },
    {
        path: '',
        loadComponent: () => import('./layouts/auth-layout/auth-layout.component').then(c => c.AuthLayoutComponent),
        children: [
            {
                path: 'login',
                canActivate: [LoginGuard],
                loadComponent: () => import('./pages/login/login.component').then(c => c.LoginComponent),
            },
            {
                path: 'server-status',
                canActivate: [ServerStatusGuard],
                loadComponent: () => import('./pages/server-status/server-status.component').then(c => c.ServerStatusComponent),
            },
        ]
    },
    {
        path: '**',
        redirectTo: 'dashboard/home'
    }
];
