export const AccessLevels = () => {
    return [
        {
            name: 'Client',
            value: 'client',
        },
        {
            name: 'User',
            value: 'user',
        }
    ];
}