export class UpdateAssetImportExportPageSize {
    static readonly type = '[Asset Import Export] Update Asset Import Export Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateAssetImportExportSearchQuery {
    static readonly type = '[Asset Import Export] Update Asset Import Export Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateAssetImportExportActivePage {
    static readonly type = '[Asset Import Export] Update Asset Import Export Active Page';
    constructor(public activePage: number) { }
}

export class UpdateAssetImportExportSort {
    static readonly type = '[Asset Import Export] Update Asset Import Export Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetAssetImportExportState {
    static readonly type = '[Asset Import Export] Reset Asset Import Export State';
    constructor() { }
}
