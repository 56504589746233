import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

import { UserService } from './user.service';

import { Template } from '@models/templates';

@Injectable({
	providedIn: 'root'
})
export class TemplateBuilderService {

	public templateBuilderWindows: { [id: string]: Window } = {};

	constructor(
		private userService: UserService
	) { }

	public initialiseTemplateBuilderIFrame() {
		const iframeId = 'template-builder-ifr';
		const iframe = document.createElement('IFRAME');
		iframe.id = iframeId
		iframe.style.display = "none";
		(<HTMLIFrameElement>iframe).src = environment.templateBuilderUrl;
		document.body.appendChild(iframe);

		const iWindow = (iframe as HTMLIFrameElement).contentWindow;
		const storageData = this.userService.getCurrentSession();
		const session = {
			cookie_name: storageData.cookie_name,
			expires: storageData.expires,
			session_id: storageData.session_id,
		}
		setTimeout(() => {
			iWindow.postMessage({ type: 'set-session', session }, environment.templateBuilderUrl);
		}, 1000);
	}

	public updateTemplateInformation(e: MessageEvent, templates: Template[]) {
		if (e.origin == environment.templateBuilderUrl) {
			if (e.data) {
				const data = e.data;
				if (data.type === 'template-title') {
					const currentTemplateIndex = templates.findIndex(x => x.id === data.id);
					if (currentTemplateIndex > -1) {
						templates[currentTemplateIndex].title = data.title;
					}
				}

				if (data.type === 'template-description') {
					const currentTemplateIndex = templates.findIndex(x => x.id === data.id);
					if (currentTemplateIndex > -1) {
						templates[currentTemplateIndex].description = data.description;
					}
				}
			}
		} else {
			return false;
		}
	}
}
