import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { UpdateUserTemplateFilters, UpdateUserTemplatesPageSize, UpdateUserTemplatesSearchQuery, UpdateUserTemplatesActivePage, UpdateUserTemplatesSort, ResetUserTemplatesState } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';
import { AssetTemplateFilters } from '@models/templates';

export interface UserTemplatesStateModel extends TableState {
    filters: AssetTemplateFilters
}

const USER_TEMPLATES_STATE_DEFAULT: UserTemplatesStateModel = {
    filters: {
        status: 'active'
    },
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        field: null,
        order: null
    },
    searchQuery: '',
    filtered: false
};

@State<UserTemplatesStateModel>({
    name: 'userTemplates',
    defaults: USER_TEMPLATES_STATE_DEFAULT
})

@Injectable()
export class UserTemplatesState {

    @Selector()
    static getUserTemplatesState(state: UserTemplatesStateModel) {
        return state;
    }

    @Selector()
    static getUserTemplateFilters(state: UserTemplatesStateModel) {
        return state.filters;
    }

    @Action(UpdateUserTemplateFilters)
    public updateUserTemplateFilters(ctx: StateContext<UserTemplatesStateModel>, action: UpdateUserTemplateFilters) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = action.filters;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUserTemplatesPageSize)
    public updateUserTemplatesPageSize(ctx: StateContext<UserTemplatesStateModel>, action: UpdateUserTemplatesPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUserTemplatesSearchQuery)
    public updateUserTemplatesSearchQuery(ctx: StateContext<UserTemplatesStateModel>, action: UpdateUserTemplatesSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUserTemplatesActivePage)
    public updateUserTemplatesActivePage(ctx: StateContext<UserTemplatesStateModel>, action: UpdateUserTemplatesActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUserTemplatesSort)
    public updateUserTemplatesSort(ctx: StateContext<UserTemplatesStateModel>, action: UpdateUserTemplatesSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = action.sort;
                draft.filtered = true;
            })
        );
    }

    @Action(ResetUserTemplatesState)
    public resetUserTemplatesState(ctx: StateContext<UserTemplatesStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = USER_TEMPLATES_STATE_DEFAULT.filters;
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = USER_TEMPLATES_STATE_DEFAULT.sort;
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
    }
}