import { AssetFilters } from '@models/assets';
import { AssetTemplate } from '@models/templates';

export class UpdateUserFilters {
    static readonly type = '[Users] Update User Filters';
    constructor(public filters: (Omit<AssetFilters, 'status'> & { status: 'enabled' | 'disabled' | 'archived'; administrators: boolean; externals: boolean; requiringUpdate: boolean })) { }
}

export class UpdateUserTypes {
    static readonly type = '[Users] Update User Types';
    constructor(public assetTypes: AssetTemplate[]) { }
}

export class UpdateUsersPageSize {
    static readonly type = '[Users] Update User Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateUsersSearchQuery {
    static readonly type = '[Users] Update User Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateUsersActivePage {
    static readonly type = '[Users] Update User Active Page';
    constructor(public activePage: number) { }
}

export class UpdateUsersSort {
    static readonly type = '[Users] Update User Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetUsersState {
    static readonly type = '[Users] Reset User State';
    constructor(public loggedOut: boolean = false) { }
}
