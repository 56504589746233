import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { UpdateUserFilters, UpdateUserTypes, UpdateUsersPageSize, UpdateUsersSearchQuery, UpdateUsersActivePage, UpdateUsersSort, ResetUsersState } from '@state-management/actions';

import { FlagDates, SortEntries } from '@data';

import { AssetFilters } from '@models/assets';
import { TableState } from '@models/shared';
import { AssetTemplate } from '@models/templates';

export interface UsersStateModel extends TableState {
    filters: (Omit<AssetFilters, 'status'> & {
        administrators: boolean;
        externals: boolean;
        requiringUpdate: boolean;
        status: 'enabled' | 'disabled' | 'archived';
    }),
    assetTypes: AssetTemplate[];
}

const USERS_STATE_DEFAULT: UsersStateModel = {
    filters: {
        status: 'enabled',
        assetTemplate: {
            id: '',
            versionId: ''
        },
        flagDate: {
            enabled: false,
            period: FlagDates()[0].value
        },
        administrators: false,
        externals: false,
        requiringUpdate: false
    },
    assetTypes: [],
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        field: null,
        order: null
    },
    searchQuery: '',
    filtered: false
};

@State<UsersStateModel>({
    name: 'users',
    defaults: USERS_STATE_DEFAULT
})

@Injectable()
export class UsersState {

    @Selector()
    static getUsersState(state: UsersStateModel) {
        return state;
    }

    @Selector()
    static getUserTypes(state: UsersStateModel) {
        return state.assetTypes;
    }

    @Selector()
    static getUserFilters(state: UsersStateModel) {
        return state.filters;
    }

    @Action(UpdateUserFilters)
    public updateUserTemplateFilters(ctx: StateContext<UsersStateModel>, action: UpdateUserFilters) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = action.filters;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUserTypes)
    public updateUserTypes(ctx: StateContext<UsersStateModel>, action: UpdateUserTypes) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.assetTypes = action.assetTypes;
                draft.filters.assetTemplate.id = draft.filters.assetTemplate.id ? draft.filters.assetTemplate.id : action.assetTypes[0].id;
                draft.filters.assetTemplate.versionId = draft.filters.assetTemplate.versionId ? draft.filters.assetTemplate.versionId : action.assetTypes[0].id;
            })
        );
    }

    @Action(UpdateUsersPageSize)
    public updateUsersPageSize(ctx: StateContext<UsersStateModel>, action: UpdateUsersPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUsersSearchQuery)
    public updateUsersSearchQuery(ctx: StateContext<UsersStateModel>, action: UpdateUsersSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUsersActivePage)
    public updateUsersActivePage(ctx: StateContext<UsersStateModel>, action: UpdateUsersActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUsersSort)
    public updateUsersSort(ctx: StateContext<UsersStateModel>, action: UpdateUsersSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = action.sort;
                draft.filtered = true;
            })
        );
    }

    @Action(ResetUsersState)
    public resetUsersState(ctx: StateContext<UsersStateModel>, action: ResetUsersState) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                const assetTemplateId = action.loggedOut ? '' : draft.assetTypes[0].id;
                draft.filters = { ...USERS_STATE_DEFAULT.filters, assetTemplate: { id: assetTemplateId, versionId: assetTemplateId } };
                if (action.loggedOut) {
                    draft.assetTypes = [];
                }
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = USERS_STATE_DEFAULT.sort;
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
    }
}