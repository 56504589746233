import { AssetFilters } from '@models/assets';
import { AssetTemplate } from '@models/templates';

export class UpdateProjectFilters {
    static readonly type = '[Projects] Update Project Filters';
    constructor(public filters: AssetFilters) { }
}

export class UpdateProjectTypes {
    static readonly type = '[Projects] Update Project Types';
    constructor(public assetTypes: AssetTemplate[]) { }
}

export class UpdateProjectsPageSize {
    static readonly type = '[Projects] Update Project Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateProjectsSearchQuery {
    static readonly type = '[Projects] Update Project Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateProjectsActivePage {
    static readonly type = '[Projects] Update Project Active Page';
    constructor(public activePage: number) { }
}

export class UpdateProjectsSort {
    static readonly type = '[Projects] Update Project Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetProjectsState {
    static readonly type = '[Projects] Reset Project State';
    constructor(public loggedOut: boolean = false) { }
}
