export class UpdateProjectImportExportPageSize {
    static readonly type = '[Project Import Export] Update Project Import Export Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateProjectImportExportSearchQuery {
    static readonly type = '[Project Import Export] Update Project Import Export Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateProjectImportExportActivePage {
    static readonly type = '[Project Import Export] Update Project Import Export Active Page';
    constructor(public activePage: number) { }
}

export class UpdateProjectImportExportSort {
    static readonly type = '[Project Import Export] Update Project Import Export Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetProjectImportExportState {
    static readonly type = '[Project Import Export] Reset Project Import Export State';
    constructor() { }
}
