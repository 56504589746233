import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { UpdateAssetImportExportPageSize, UpdateAssetImportExportSearchQuery, UpdateAssetImportExportActivePage, UpdateAssetImportExportSort, ResetAssetImportExportState } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';

export interface AssetImportExportStateModel extends TableState { }

const ASSET_IMPORT_EXPORT_STATE_DEFAULT: AssetImportExportStateModel = {
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        field: null,
        order: null
    },
    searchQuery: '',
    filtered: false
};

@State<AssetImportExportStateModel>({
    name: 'assetImportExport',
    defaults: ASSET_IMPORT_EXPORT_STATE_DEFAULT
})

@Injectable()
export class AssetImportExportState {

    @Selector()
    static getImportExportState(state: AssetImportExportStateModel) {
        return state;
    }

    @Action(UpdateAssetImportExportPageSize)
    public updateImportExportPageSize(ctx: StateContext<AssetImportExportStateModel>, action: UpdateAssetImportExportPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetImportExportSearchQuery)
    public updateImportExportSearchQuery(ctx: StateContext<AssetImportExportStateModel>, action: UpdateAssetImportExportSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetImportExportActivePage)
    public updateImportExportActivePage(ctx: StateContext<AssetImportExportStateModel>, action: UpdateAssetImportExportActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetImportExportSort)
    public updateImportExportSort(ctx: StateContext<AssetImportExportStateModel>, action: UpdateAssetImportExportSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = action.sort;
                draft.filtered = true;
            })
        );
    }

    @Action(ResetAssetImportExportState)
    public resetImportExportState(ctx: StateContext<AssetImportExportStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = ASSET_IMPORT_EXPORT_STATE_DEFAULT.sort;
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
    }
}