import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import swal from 'sweetalert2';
import { Store } from '@ngxs/store';

import { map } from 'rxjs';

import { ConfigService, StorageService, UserService } from '@services';

import { ResetAssetTemplatesState, ResetAssetsState, ResetAuditsState, ResetSettingsState } from '@state-management/actions';

import { Asset } from '@models/assets';
import { Audit } from '@models/audits';
import { Client } from '@models/clients';
import { CustomAsset, CustomAuditWithReports } from '@models/custom';
import { AssetFormConfig } from '@models/form-configs';
import { Image } from '@models/images';
import { StandardReport } from '@models/reports';
import { UserContact } from '@models/shared';
import { AssetTemplate, AuditTemplate } from '@models/templates';
import { User, UserPermissions, UserRole, UserSignature, UserStatus } from '@models/users';
import { ImportStatus } from '@models/imports';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private router: Router,
        private storageService: StorageService,
        private store: Store,
        private userService: UserService
    ) { }

    // ASSET TEMPLATES

    public activateAssetTemplate(assetTemplateId: string) {
        const url = this.configService.dashboardClientApiUrl + '/asset_templates/activate/' + assetTemplateId;
        return this.makeRequest<string>('post', url);
    }

    public archiveAssetTemplate(assetTemplateId: string) {
        const url = this.configService.dashboardClientApiUrl + '/asset_templates/archive/' + assetTemplateId;
        return this.makeRequest<string>('put', url);
    }

    public deleteAssetTemplate(assetTemplateId: string) {
        const url = this.configService.dashboardClientApiUrl + '/asset_templates/' + assetTemplateId;
        return this.makeRequest<string>('delete', url);
    }

    public duplicateAssetTemplate(assetTemplateId: string) {
        const url = this.configService.dashboardClientApiUrl + '/asset_templates/duplicate/' + assetTemplateId;
        return this.makeRequest<string>('post', url);
    }

    public getAssetTemplate(assetTemplateId: string) {
        const url = this.configService.dashboardClientApiUrl + '/asset_templates/' + assetTemplateId;
        return this.makeRequest<AssetTemplate>('get', url);
    }

    public getAssetFormConfig(assetFormConfigId: string) {
        let url = `${this.configService.dashboardClientApiUrl}/asset_templates/form_config/${assetFormConfigId}`;
        return this.makeRequest<AssetFormConfig>('get', url);
    }

    public getLatestAssetFormConfig(assetFormConfigId: string) {
        let url = `${this.configService.dashboardClientApiUrl}/asset_templates/latest_form_config/${assetFormConfigId}`;
        return this.makeRequest<AssetFormConfig>('get', url);
    }

    public listAssetTemplatesByStatus(status: string, assetType: string) {
        const url = this.configService.dashboardClientApiUrl + '/asset_templates/by_status/' + status + '/' + assetType;
        return this.makeRequest<AssetTemplate[]>('get', url);
    }

    public listAssetFormConfigs(assetType: string) {
        const url = this.configService.dashboardClientApiUrl + '/asset_templates/form_configs/' + assetType;
        return this.makeRequest<AssetFormConfig[]>('get', url);
    }

    public updateAssetTemplate(assetTemplateId: string) {
        const url = this.configService.dashboardClientApiUrl + '/asset_templates/new_version/' + assetTemplateId;
        return this.makeRequest<string>('post', url);
    }

    // ASSETS

    public archiveAsset(assetId: string) {
        const url = this.configService.dashboardClientApiUrl + '/assets/archive/' + assetId;
        return this.makeRequest<string>('put', url);
    }

    public createAsset(assetFormConfigId: string, model: Record<string, any>) {
        const url = this.configService.dashboardClientApiUrl + '/assets';
        const body = {
            asset_form_config_id: assetFormConfigId,
            model
        }
        return this.makeRequest<string>('post', url, body);
    }

    public duplicateAsset(assetId: string) {
        const url = this.configService.dashboardClientApiUrl + '/assets/duplicate/' + assetId;
        return this.makeRequest<{ asset: Asset, assetFormConfig: AssetFormConfig }>('post', url);
    }

    public getAsset(assetId: string) {
        const url = this.configService.dashboardClientApiUrl + '/assets/asset/' + assetId;
        return this.makeRequest<Asset>('get', url);
    }

    public getAssetModel(assetId: string) {
        const url = this.configService.dashboardClientApiUrl + '/assets/model/' + assetId;
        return this.makeRequest<{ assetModel: Asset, assetFormConfig: AssetFormConfig }>('get', url);
    }

    public getLatestAssetModel(assetId: string) {
        const url = this.configService.dashboardClientApiUrl + '/assets/latest_model/' + assetId;
        return this.makeRequest<{ assetModel: Asset, assetFormConfig: AssetFormConfig }>('get', url);
    }

    public listAssets(status: string, assetType: string, assetTemplateId?: string, flagDate?: number) {
        const url = this.configService.dashboardClientApiUrl + '/assets/by_status/' + status;
        const body = {
            asset_template_id: assetTemplateId,
            asset_type: assetType,
            flag_date: flagDate
        }
        return this.makeRequest<CustomAsset[]>('post', url, body);
    }

    public listAssetTypes(assetTemplateId: string, status: string) {
        const url = this.configService.dashboardClientApiUrl + '/assets/asset_type_versions/' + assetTemplateId + '/' + status;
        return this.makeRequest<{ id: string; version: number }[]>('get', url);
    }

    public listAssetAuditsHistory(assetId: string) {
        const url = this.configService.dashboardClientApiUrl + '/assets/audit_history/' + assetId;
        return this.makeRequest<(Audit & { contact: UserContact })[]>('get', url);
    }

    public listAssetUpdatesHistory(assetId: string) {
        const url = this.configService.dashboardClientApiUrl + '/assets/update_history/' + assetId;
        return this.makeRequest<CustomAsset[]>('get', url);
    }

    public updateAsset(assetId: string, assetFormConfigId: string, asset: Asset) {
        const url = this.configService.dashboardClientApiUrl + '/assets/' + assetId + '/' + assetFormConfigId;
        const body = {
            asset
        }
        return this.makeRequest<Asset>('put', url, body);
    }

    // AUDIT TEMPLATES

    public activateAuditTemplate(auditTemplateId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audit_templates/' + auditTemplateId + '/activate';
        return this.makeRequest<string>('post', url);
    }

    public getAuditTemplate(auditTemplateId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audit_templates/' + auditTemplateId;
        return this.makeRequest<AuditTemplate>('get', url);
    }

    // AUDITS

    public archiveAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/archive';
        return this.makeRequest<string>('post', url);
    }

    public deleteAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId;
        return this.makeRequest<string>('delete', url);
    }

    public getAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId;
        return this.makeRequest<(Audit & { contact: UserContact })>('get', url);
    }

    public getAuditWithReports(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/with_report';
        return this.makeRequest<(Audit & { contact: UserContact; reports: Partial<Report>[] })>('get', url);
    }

    public listAuditsWithReports(auditTypes: string[], startDate: string, endDate: string, status: string[], failures: string[], signOffRequired: boolean = false) {
        const url = this.configService.dashboardClientApiUrl + '/audits/with_reports';
        const body = {
            audit_types: auditTypes,
            start_date: startDate,
            end_date: endDate,
            failures,
            status,
            sign_off_required: signOffRequired
        };

        return this.makeRequest<(CustomAuditWithReports[])>('post', url, body);
    }

    public reassignAudit(auditId: string, userId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/reassign';
        const body = {
            user_id: userId
        }
        return this.makeRequest<string>('post', url, body);
    }

    public reopenAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/reopen';
        return this.makeRequest<string>('post', url, {});
    }

    public signOff(auditId: string, comments: string) {
        const url = this.configService.dashboardClientApiUrl + '/audits/' + auditId + '/sign_off';
        const body = { comments };
        return this.makeRequest<string>('post', url, body);
    }

    // CLIENTS

    public getClient(clientId: string) {
        const url = this.configService.dashboardClientApiUrl + '/clients/' + clientId;
        return this.makeRequest<Client>('get', url);
    }

    // DASHBOARD

    // EXPORTS

    public exportAssets(assetFormConfigId: string) {
        const url = this.configService.dashboardClientApiUrl + '/exports/assets';
        const body = {
            asset_form_config_id: assetFormConfigId
        }
        return this.makeRequest<{ fileName: string, base64: string }>('post', url, body);
    }

    // IMAGES

    public getImage(imageId: string) {
        const url = this.configService.dashboardClientApiUrl + '/images/image/' + imageId;
        return this.makeRequest<Image>('get', url);
    }

    public getImageThumbnail(imageId: string) {
        const url = this.configService.dashboardClientApiUrl + '/images/thumbnail/' + imageId;
        return this.makeRequest<string>('get', url);
    }

    public getImageIdsByAudit(auditId: string) {
        const url = this.configService.dashboardClientApiUrl + '/admin/images/ids_by_audit/' + auditId;
        return this.makeRequest<{ [key: string]: { id: string } }>('get', url);
    }

    // IMPORTS

    public getImportStatus(importType: string) {
        const url = this.configService.dashboardClientApiUrl + '/imports/status/' + importType;
        return this.makeRequest<ImportStatus>('get', url);
    }

    public importAssets(assetFormConfigId: string, fileName: string, base64: string, importStatusId: string, importType: string) {
        const url = this.configService.dashboardClientApiUrl + '/imports/assets';
        const body = {
            asset_form_config_id: assetFormConfigId,
            file_name: fileName,
            base64: base64,
            import_status_id: importStatusId,
            import_type: importType
        };
        return this.makeRequest<string>('post', url, body);
    }

    public listImports(importType: string) {
        const url = this.configService.dashboardClientApiUrl + '/imports/statuses/' + importType;
        return this.makeRequest<(ImportStatus & { contact: UserContact })[]>('get', url);
    }

    public validateImportExcel(assetFormConfigId: string, base64: string) {
        const url = this.configService.dashboardClientApiUrl + '/imports/validate/excel';
        const body = {
            asset_form_config_id: assetFormConfigId,
            base64
        }
        return this.makeRequest<string[]>('post', url, body);
    }

    // REPORTS

    public generateAssetFlagDateReport(assetFormConfigId: string, flagDate: number) {
        const url = this.configService.dashboardClientApiUrl + '/reports/asset_flag_date_report/' + assetFormConfigId;
        const body = {
            flag_date: flagDate
        }
        return this.makeRequest<{ fileName: string, base64: string }>('post', url, body);
    }

    public generateAssetUpdatesHistoryReport(assetFormConfigId: string) {
        const url = this.configService.dashboardClientApiUrl + '/reports/asset_updates_history_report/' + assetFormConfigId;
        return this.makeRequest<{ fileName: string, base64: string }>('post', url);
    }

    public generateBackendExcel(reportId: string, reportParams: Record<string, string>, codeModel: string) {
        const url = this.configService.dashboardClientApiUrl + '/admin/reports/generate_backend_excel/' + reportId;
        const body = {
            code_model: codeModel,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, base64: any }>('post', url, body);
    }

    public generateExcel(auditId: string, reportId: string, reportParams: Record<string, any>) {
        const url = this.configService.dashboardClientApiUrl + '/reports/custom_reports/generate_excel/' + auditId;
        const body = {
            report_id: reportId,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, base64: string }>('post', url, body);
    }

    public generateBackendPdf(reportId: string, reportParams: Record<string, string>, codeModel: string) {
        const url = this.configService.dashboardClientApiUrl + '/admin/reports/generate_backend_pdf/' + reportId;
        const body = {
            code_model: codeModel,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, pdf: string }>('post', url, body);
    }

    public generatePdf(auditId: string, reportId: string, reportParams: Record<string, any>) {
        const url = this.configService.dashboardClientApiUrl + '/reports/custom_reports/generate_pdf/' + auditId;
        const body = {
            report_id: reportId,
            report_params: reportParams
        };
        return this.makeRequest<{ fileName: string, pdf: string }>('post', url, body);
    }

    public generateStandardTemplatePdf(templateId: string) {
        const url = this.configService.dashboardClientApiUrl + '/reports/standard_template_pdf/' + templateId;
        return this.makeRequest<{ fileName: string, pdf: string }>('get', url);
    }

    public listStandardReports(status: string) {
        let url = this.configService.dashboardClientApiUrl + '/reports/standard_reports/' + status;
        return this.makeRequest<StandardReport[]>('get', url);
    }

    // ROLES

    public getRoles() {
        const url = this.configService.dashboardClientApiUrl + '/roles';
        return this.makeRequest<UserRole[]>('get', url);
    }

    // USERS

    public createUser(user: { contact: UserContact, password: string, is_admin: boolean, is_external: boolean, role_id: string, access_level: string, asset_form_config_id: string, asset_model: Asset }) {
        const url = this.configService.dashboardClientApiUrl + '/users/create';
        const body = {
            ...user
        }
        return this.makeRequest<string>('post', url, body);
    }

    public getLatestUserProfile(userId: string) {
        const url = this.configService.dashboardClientApiUrl + '/users/latest_model/' + userId;
        return this.makeRequest<{ assetModel: Asset, assetFormConfig: AssetFormConfig }>('get', url);
    }

    public getUserAccount(userId: string) {
        const url = this.configService.dashboardClientApiUrl + '/users/account/' + userId;
        return this.makeRequest<(User & { permissions: UserPermissions; role_name: string; role_id: string })>('get', url);
    }

    public getUserStatus(userId: string) {
        const url = this.configService.dashboardClientApiUrl + '/users/status/' + userId;
        return this.makeRequest<UserStatus>('get', url);
    }

    public getUserSignature(signatureId: string) {
        const url = this.configService.dashboardClientApiUrl + '/users/signature/' + signatureId;
        return this.makeRequest<UserSignature>('get', url);
    }

    public listUsersByStatus(status: 'enabled' | 'disabled' | 'archived', usersRequiringUpdate = false, administrators = false, externals = false, flagDate: number = null) {
        const url = this.configService.dashboardClientApiUrl + '/users/by_status/' + status;
        const body = {
            administrators,
            externals,
            users_requiring_update: usersRequiringUpdate,
            flag_date: flagDate
        }
        return this.makeRequest<(User & { role_name: string })[]>('post', url, body);
    }

    public updateUser(userId: string, user: { contact: UserContact, is_admin: boolean, role_id: string; access_level: string }) {
        const url = this.configService.dashboardClientApiUrl + '/users/' + userId;
        const body = {
            ...user
        }
        return this.makeRequest<string>('put', url, body);
    }

    public updateUserProfile(userId: string, assetFormConfigId: string, assetModel: Asset) {
        const url = this.configService.dashboardClientApiUrl + '/users/asset/' + userId + '/' + assetFormConfigId;
        const body = {
            asset_model: assetModel
        }
        return this.makeRequest<string>('put', url, body);
    }

    public updateUserSignature(userId: string, signature: string) {
        const url = this.configService.dashboardClientApiUrl + '/users/signature/' + userId;
        const body = {
            signature
        }
        return this.makeRequest<string>('put', url, body);
    }

    // HELPER FUNCTIONS

    private makeRequest<T>(method: 'get' | 'post' | 'put' | 'delete', url: string, body?: any, responseType?: any, params?: any) {
        return new Promise<T>((resolve, reject) => {
            const headers = new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.userService.getCurrentSession().session_id
                }
            );

            const options = {
                headers,
                responseType: null,
                params: null
            };

            if (responseType) {
                options.responseType = responseType;
            }

            if (params) {
                options.params = params;
            }

            const handleError = (error: any) => {
                if (error.error && (error.error === 'Invalid user' || error.error === 'Session expired' || error.error === 'Invalid session')) {
                    swal.fire({
                        title: 'Session Expired',
                        text: 'Your session has expired. Please sign in again.',
                        icon: 'warning',
                        buttonsStyling: false,
                        backdrop: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'btn btn-tablue'
                        }
                    }).then(result => {
                        if (result.isConfirmed) {
                            this.storageService.removeFromStorage(this.configService.userSessionKey);
                            this.router.navigate(['/login']);
                            this.store.dispatch([ResetAssetTemplatesState, ResetAssetsState, ResetAuditsState, ResetSettingsState]);
                        }
                    });
                } else if (error.error && (error.error === 'No response')) {
                    swal.fire({
                        title: 'Error Connecting To Gateway',
                        text: 'Please contact administrator.',
                        icon: 'warning',
                        buttonsStyling: false,
                        backdrop: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'btn btn-tablue'
                        }
                    }).then(result => {
                        if (result.isConfirmed) {
                            reject(error);
                        }
                    });
                } else if (!error.error) {
                    swal.fire({
                        title: 'Unexpected Error',
                        text: 'Please contact administrator.',
                        icon: 'warning',
                        buttonsStyling: false,
                        backdrop: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: 'btn btn-tablue'
                        }
                    }).then(result => {
                        if (result.isConfirmed) {
                            reject(error);
                        }
                    });
                } else {
                    reject(error);
                }
            };

            if (method === 'get') {
                this.httpClient.get<T>(url, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            } else if (method === 'post') {
                this.httpClient.post<T>(url, body, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            } else if (method === 'put') {
                this.httpClient.put<T>(url, body, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            } else if (method === 'delete') {
                this.httpClient.delete<T>(url, options)
                    .pipe(map(response => response))
                    .subscribe({
                        next: response => resolve(response),
                        error: error => handleError(error)
                    });
            }
        });
    }
}
