import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { UpdateProjectTemplateFilters, UpdateProjectTemplatesPageSize, UpdateProjectTemplatesSearchQuery, UpdateProjectTemplatesActivePage, UpdateProjectTemplatesSort, ResetProjectTemplatesState } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';
import { AssetTemplateFilters } from '@models/templates';

export interface ProjectTemplatesStateModel extends TableState {
    filters: AssetTemplateFilters
}

const PROJECT_TEMPLATES_STATE_DEFAULT: ProjectTemplatesStateModel = {
    filters: {
        status: 'active'
    },
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        field: null,
        order: null
    },
    searchQuery: '',
    filtered: false
};

@State<ProjectTemplatesStateModel>({
    name: 'projectTemplates',
    defaults: PROJECT_TEMPLATES_STATE_DEFAULT
})

@Injectable()
export class ProjectTemplatesState {

    @Selector()
    static getProjectTemplatesState(state: ProjectTemplatesStateModel) {
        return state;
    }

    @Selector()
    static getProjectTemplateFilters(state: ProjectTemplatesStateModel) {
        return state.filters;
    }

    @Action(UpdateProjectTemplateFilters)
    public updateProjectTemplateFilters(ctx: StateContext<ProjectTemplatesStateModel>, action: UpdateProjectTemplateFilters) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = action.filters;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateProjectTemplatesPageSize)
    public updateProjectTemplatesPageSize(ctx: StateContext<ProjectTemplatesStateModel>, action: UpdateProjectTemplatesPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateProjectTemplatesSearchQuery)
    public updateProjectTemplatesSearchQuery(ctx: StateContext<ProjectTemplatesStateModel>, action: UpdateProjectTemplatesSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateProjectTemplatesActivePage)
    public updateProjectTemplatesActivePage(ctx: StateContext<ProjectTemplatesStateModel>, action: UpdateProjectTemplatesActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateProjectTemplatesSort)
    public updateProjectTemplatesSort(ctx: StateContext<ProjectTemplatesStateModel>, action: UpdateProjectTemplatesSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = action.sort;
                draft.filtered = true;
            })
        );
    }

    @Action(ResetProjectTemplatesState)
    public resetProjectTemplatesState(ctx: StateContext<ProjectTemplatesStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = PROJECT_TEMPLATES_STATE_DEFAULT.filters;
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = PROJECT_TEMPLATES_STATE_DEFAULT.sort;
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
    }
}