import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ConfigService {

    public authApiUrl: string;
    public dashboardClientApiUrl: string;
    public dashboardUrl: string;
    public secretKey: string;
    public templateBuilderUrl: string;
    public userSessionKey = 'ta_session';

    constructor() {
        this.dashboardClientApiUrl = environment.dashboardClientApiUrl;
        this.authApiUrl = environment.dashboardAuthApiUrl;
        this.dashboardUrl = environment.dashboardUrl;
        this.secretKey = environment.secretKey;
        this.templateBuilderUrl = environment.templateBuilderUrl;
    }
}
