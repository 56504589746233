import { AssetTemplateFilters } from '@models/templates';

export class UpdateProjectTemplateFilters {
    static readonly type = '[Project Templates] Update Project Template Filters';
    constructor(public filters: AssetTemplateFilters) { }
}

export class UpdateProjectTemplatesPageSize {
    static readonly type = '[Project Templates] Update Project Templates Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateProjectTemplatesSearchQuery {
    static readonly type = '[Project Templates] Update Project Templates Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateProjectTemplatesActivePage {
    static readonly type = '[Project Templates] Update Project Templates Active Page';
    constructor(public activePage: number) { }
}

export class UpdateProjectTemplatesSort {
    static readonly type = '[Project Templates] Update Project Templates Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetProjectTemplatesState {
    static readonly type = '[Project Templates] Reset Project Templates State';
    constructor() { }
}
