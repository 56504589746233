import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { UpdateProjectImportExportPageSize, UpdateProjectImportExportSearchQuery, UpdateProjectImportExportActivePage, UpdateProjectImportExportSort, ResetProjectImportExportState } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';

export interface ProjectImportExportStateModel extends TableState { }

const PROJECT_IMPORT_EXPORT_STATE_DEFAULTÏ: ProjectImportExportStateModel = {
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        field: null,
        order: null
    },
    searchQuery: '',
    filtered: false
};

@State<ProjectImportExportStateModel>({
    name: 'projectImportExport',
    defaults: PROJECT_IMPORT_EXPORT_STATE_DEFAULTÏ
})

@Injectable()
export class ProjectImportExportState {

    @Selector()
    static getImportExportState(state: ProjectImportExportStateModel) {
        return state;
    }

    @Action(UpdateProjectImportExportPageSize)
    public updateImportExportPageSize(ctx: StateContext<ProjectImportExportStateModel>, action: UpdateProjectImportExportPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateProjectImportExportSearchQuery)
    public updateImportExportSearchQuery(ctx: StateContext<ProjectImportExportStateModel>, action: UpdateProjectImportExportSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateProjectImportExportActivePage)
    public updateImportExportActivePage(ctx: StateContext<ProjectImportExportStateModel>, action: UpdateProjectImportExportActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateProjectImportExportSort)
    public updateImportExportSort(ctx: StateContext<ProjectImportExportStateModel>, action: UpdateProjectImportExportSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = action.sort;
                draft.filtered = true;
            })
        );
    }

    @Action(ResetProjectImportExportState)
    public resetImportExportState(ctx: StateContext<ProjectImportExportStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = PROJECT_IMPORT_EXPORT_STATE_DEFAULTÏ.sort;
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
    }
}