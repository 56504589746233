import { AssetTemplateFilters } from '@models/templates';

export class UpdateUserTemplateFilters {
    static readonly type = '[User Templates] Update User Template Filters';
    constructor(public filters: AssetTemplateFilters) { }
}

export class UpdateUserTemplatesPageSize {
    static readonly type = '[User Templates] Update User Templates Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateUserTemplatesSearchQuery {
    static readonly type = '[User Templates] Update User Templates Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateUserTemplatesActivePage {
    static readonly type = '[User Templates] Update User Templates Active Page';
    constructor(public activePage: number) { }
}

export class UpdateUserTemplatesSort {
    static readonly type = '[User Templates] Update User Templates Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetUserTemplatesState {
    static readonly type = '[User Templates] Reset User Templates State';
    constructor() { }
}
