import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngxs/store';

import { ApiService, UserService } from '@services';
import { UpdateUser } from '../state-management/actions/user';
import { UpdateClient } from '@state-management/actions';

export const UserAndClientResolver: ResolveFn<boolean> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const apiService = inject(ApiService);
    const userService = inject(UserService);
    const store = inject(Store);
    const session = userService.getCurrentSession();
    const user = await apiService.getUserAccount(session.user_id);
    const client = await apiService.getClient(session.client_id);
    store.dispatch(new UpdateUser(user));
    store.dispatch(new UpdateClient(client));

    return true;
}
