import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class StorageService {

    constructor(
        private configService: ConfigService
    ) { }

    public saveToStorage<T>(key: string, data: T) {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.configService.secretKey).toString();
        localStorage.setItem(key, encryptedData);
    }

    public getFromStorage<T>(key: string) {
        const encryptedData = localStorage.getItem(key);
        if (encryptedData) {
            const data = CryptoJS.AES.decrypt(encryptedData, this.configService.secretKey).toString(CryptoJS.enc.Utf8);
            return JSON.parse(data) as T;
        }
        return null;
    }

    public removeFromStorage(key: string) {
        localStorage.removeItem(key);
    }
}
